
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Component, Prop } from 'vue-property-decorator';
import { CampaignDetail } from '@/models/campaigns/campaignDetail';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import cloudinaryImageResize from '@/helpers/cloudinary-image-resize';

@Component
export default class CampaignCard extends LoggedInComponentBase {
  @Prop() campaign!: CampaignDetail;
  @Prop() category!: CampaignCategory;

  public get imageUrl() {
    return cloudinaryImageResize(this.campaign.blobUrl, 300);
  }
}
